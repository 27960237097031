import React, { Component } from 'react'
import NavigationContainers from '../../components/navigation-containers'

// Components Globals
import Header from 'components/header'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import { BannerConteudo, SectionVideos } from '../../Style/conteudos'
import { Container, SeloTitle } from '../../Style/global'

import BoxVideo from 'components/box-video'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Sass Global
import 'sass/main.scss'

const PageConteudos = () => {
  const images = useStaticQuery(graphql`
    query {
      item01: file(relativePath: { eq: "webnarios/como-transformar-farmacia-maquina-vendas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item02: file(relativePath: { eq: "webnarios/saiba-porque-rupturas-estoque-estao-quebrando-sua-farmacia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item03: file(relativePath: { eq: "webnarios/o-poder-das-pessoas-no-sucesso-da-farmacia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item04: file(relativePath: { eq: "webnarios/o-impacto-da-operacao-de-caixa-nas-vendas-e-lucratividade-da-farmacia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item05: file(relativePath: { eq: "webnarios/como-saber-se-farmacia-esta-pagando-imposto-corretamente.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item06: file(relativePath: { eq: "webnarios/como-descobrir-lucro-da-farmacia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      item07: file(relativePath: { eq: "webnarios/aprenda-criar-calendario-acoes-marketing-farmacia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="main">
      <SEO title="Webinários" />

      <Header />

      <BannerConteudo>
        <Container className="cont">
          <SeloTitle>Conteúdos</SeloTitle>
          <h1>Conteúdos para descomplicar a gestão do seu negócio</h1>
          <NavigationContainers />
        </Container>
      </BannerConteudo>

      <SectionVideos>
        <Container>
          <div className="all">
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/calendario-marketing"
              img_video_optimized={
                <Img
                  fluid={images.item07.childImageSharp.fluid}
                  alt="Imagem vídeo aprenda a criar um calendário para Ações de Marketing na Farmácia!"
                  className="thumb"
                />
              }
              titulo_video="Aprenda a criar um calendário para Ações de Marketing na Farmácia!"
              descricao_video="Nesse webinário, vamos te ensinar a criar um calendário de marketing para que as principais datas não passem despercebidas e sua farmácia lucre."
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-vendas"
              img_video_optimized={
                <Img
                  fluid={images.item01.childImageSharp.fluid}
                  alt="Imagem vídeo como transformar sua farmácia em uma máquina de vendas?"
                  className="thumb"
                />
              }
              titulo_video="Como transformar sua farmácia em uma máquina de vendas?"
              descricao_video="Nesse webinário, você aprende com especialistas, quais ações na prática vão fazer da sua farmácia uma verdadeira máquina em vendas!"
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-estoque"
              img_video_optimized={
                <Img
                  fluid={images.item02.childImageSharp.fluid}
                  alt="Imagem vídeo saiba porque as rupturas de estoque estão quebrando a sua farmácia"
                  className="thumb"
                />
              }
              titulo_video="Saiba porque as rupturas de estoque estão quebrando a sua ..."
              descricao_video="Nesse webinário você aprende com especialistas a como evitar a falta de produtos no seu estoque. São técnicas simples que você pode ..."
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-gestao-pessoas"
              img_video_optimized={
                <Img
                  fluid={images.item03.childImageSharp.fluid}
                  alt="Imagem vídeo o poder das pessoas no sucesso da farmácia"
                  className="thumb"
                />
              }
              titulo_video="O poder das pessoas no sucesso da farmácia"
              descricao_video="Nesse webinário, você aprenderá com os especialistas Eusdete de Deus (Psicóloga Organizacional) e Vitor Costa (Consultor em Marketing e Gestão ..."
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-caixa"
              img_video_optimized={
                <Img
                  fluid={images.item04.childImageSharp.fluid}
                  alt="Imagem vídeo o impacto da operação de caixa nas vendas e lucratividade da farmácia"
                  className="thumb"
                />
              }
              titulo_video="O impacto da operação de caixa nas vendas e lucratividade da farmácia"
              descricao_video="Nesse webinário você aprende [de verdade] com especialistas a melhorar as operações de caixa da sua farmácia."
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-fiscal"
              img_video_optimized={
                <Img
                  fluid={images.item05.childImageSharp.fluid}
                  alt="Imagem vídeo como saber se a farmácia está pagando o imposto corretamente"
                  className="thumb"
                />
              }
              titulo_video="Como saber se a farmácia está pagando o imposto corretamente"
              descricao_video="Nesse webinário, vamos conversar com especialista Fiscal, Osmar Freitas para conhecer mais sobre os impostos, segregação de imposto, ..."
            />
            <BoxVideo
              url_video="https://conteudo.inovafarma.com.br/webinario-financeiro"
              img_video_optimized={
                <Img
                  fluid={images.item06.childImageSharp.fluid}
                  alt="Imagem vídeo como descobrir o lucro da minha farmácia?"
                  className="thumb"
                />
              }
              titulo_video="Como descobrir o lucro da minha farmácia?"
              descricao_video="Neste webinário vamos mostrar a importância do controle financeiro nas farmácias e destacar os principais problemas causados ..."
            />
          </div>
        </Container>
      </SectionVideos>

      <Demo />

      <Footer padding_maior={true} />
    </div>
  )
}

export default PageConteudos
